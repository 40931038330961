/* margin */
.margin.m0{ margin: 0px !important; }
.margin.m1{ margin: 1px !important; }
.margin.m2{ margin: 2px !important; }
.margin.m3{ margin: 3px !important; }
.margin.m4{ margin: 4px !important; }
.margin.m5{ margin: 5px !important; }
.margin.m6{ margin: 6px !important; }
.margin.m7{ margin: 7px !important; }
.margin.m8{ margin: 8px !important; }
.margin.m9{ margin: 9px !important; }
.margin.m10{ margin: 10px !important; }
.margin.m12{ margin: 12px !important; }
.margin.m14{ margin: 14px !important; }
.margin.m16{ margin: 16px !important; }
.margin.m18{ margin: 18px !important; }
.margin.m20{ margin: 20px !important; }
.margin.m22{ margin: 22px !important; }
.margin.m24{ margin: 24px !important; }
.margin.m26{ margin: 26px !important; }
.margin.m28{ margin: 28px !important; }
.margin.m30{ margin: 30px !important; }
.margin.m32{ margin: 32px !important; }
.margin.m34{ margin: 34px !important; }
.margin.m36{ margin: 36px !important; }
.margin.m38{ margin: 38px !important; }
.margin.m40{ margin: 40px !important; }
.margin.m42{ margin: 42px !important; }
.margin.m44{ margin: 44px !important; }
.margin.m46{ margin: 46px !important; }
.margin.m48{ margin: 48px !important; }
.margin.m50{ margin: 50px !important; }
.margin.m52{ margin: 52px !important; }
.margin.m54{ margin: 54px !important; }
.margin.m56{ margin: 56px !important; }
.margin.m58{ margin: 58px !important; }
.margin.m60{ margin: 60px !important; }
.margin-auto.m0{ margin: 0px auto !important; }
.margin-auto.m1{ margin: 1px auto !important; }
.margin-auto.m2{ margin: 2px auto !important; }
.margin-auto.m3{ margin: 3px auto !important; }
.margin-auto.m4{ margin: 4px auto !important; }
.margin-auto.m5{ margin: 5px auto !important; }
.margin-auto.m6{ margin: 6px auto !important; }
.margin-auto.m7{ margin: 7px auto !important; }
.margin-auto.m8{ margin: 8px auto !important; }
.margin-auto.m9{ margin: 9px auto !important; }
.margin-auto.m10{ margin: 10px auto !important; }
.margin-auto.m12{ margin: 12px auto !important; }
.margin-auto.m14{ margin: 14px auto !important; }
.margin-auto.m16{ margin: 16px auto !important; }
.margin-auto.m18{ margin: 18px auto !important; }
.margin-auto.m20{ margin: 20px auto !important; }
.margin-auto.m22{ margin: 22px auto !important; }
.margin-auto.m24{ margin: 24px auto !important; }
.margin-auto.m26{ margin: 26px auto !important; }
.margin-auto.m28{ margin: 28px auto !important; }
.margin-auto.m30{ margin: 30px auto !important; }
.margin-auto.m32{ margin: 32px auto !important; }
.margin-auto.m34{ margin: 34px auto !important; }
.margin-auto.m36{ margin: 36px auto !important; }
.margin-auto.m38{ margin: 38px auto !important; }
.margin-auto.m40{ margin: 40px auto !important; }
.margin-auto.m42{ margin: 42px auto !important; }
.margin-auto.m44{ margin: 44px auto !important; }
.margin-auto.m46{ margin: 46px auto !important; }
.margin-auto.m48{ margin: 48px auto !important; }
.margin-auto.m50{ margin: 50px auto !important; }
.margin-auto.m52{ margin: 52px auto !important; }
.margin-auto.m54{ margin: 54px auto !important; }
.margin-auto.m56{ margin: 56px auto !important; }
.margin-auto.m58{ margin: 58px auto !important; }
.margin-auto.m60{ margin: 60px auto !important; }
.margin-top.m0{ margin-top: 0px !important; }
.margin-top.m1{ margin-top: 1px !important; }
.margin-top.m2{ margin-top: 2px !important; }
.margin-top.m3{ margin-top: 3px !important; }
.margin-top.m4{ margin-top: 4px !important; }
.margin-top.m5{ margin-top: 5px !important; }
.margin-top.m6{ margin-top: 6px !important; }
.margin-top.m7{ margin-top: 7px !important; }
.margin-top.m8{ margin-top: 8px !important; }
.margin-top.m9{ margin-top: 9px !important; }
.margin-top.m10{ margin-top: 10px !important; }
.margin-top.m12{ margin-top: 12px !important; }
.margin-top.m14{ margin-top: 14px !important; }
.margin-top.m16{ margin-top: 16px !important; }
.margin-top.m18{ margin-top: 18px !important; }
.margin-top.m20{ margin-top: 20px !important; }
.margin-top.m22{ margin-top: 22px !important; }
.margin-top.m24{ margin-top: 24px !important; }
.margin-top.m26{ margin-top: 26px !important; }
.margin-top.m28{ margin-top: 28px !important; }
.margin-top.m30{ margin-top: 30px !important; }
.margin-top.m32{ margin-top: 32px !important; }
.margin-top.m34{ margin-top: 34px !important; }
.margin-top.m36{ margin-top: 36px !important; }
.margin-top.m38{ margin-top: 38px !important; }
.margin-top.m40{ margin-top: 40px !important; }
.margin-top.m42{ margin-top: 42px !important; }
.margin-top.m44{ margin-top: 44px !important; }
.margin-top.m46{ margin-top: 46px !important; }
.margin-top.m48{ margin-top: 48px !important; }
.margin-top.m50{ margin-top: 50px !important; }
.margin-top.m52{ margin-top: 52px !important; }
.margin-top.m54{ margin-top: 54px !important; }
.margin-top.m56{ margin-top: 56px !important; }
.margin-top.m58{ margin-top: 58px !important; }
.margin-top.m60{ margin-top: 60px !important; }
.margin-right.m0{ margin-right: 0px !important; }
.margin-right.m1{ margin-right: 1px !important; }
.margin-right.m2{ margin-right: 2px !important; }
.margin-right.m3{ margin-right: 3px !important; }
.margin-right.m4{ margin-right: 4px !important; }
.margin-right.m5{ margin-right: 5px !important; }
.margin-right.m6{ margin-right: 6px !important; }
.margin-right.m7{ margin-right: 7px !important; }
.margin-right.m8{ margin-right: 8px !important; }
.margin-right.m9{ margin-right: 9px !important; }
.margin-right.m10{ margin-right: 10px !important; }
.margin-right.m12{ margin-right: 12px !important; }
.margin-right.m14{ margin-right: 14px !important; }
.margin-right.m16{ margin-right: 16px !important; }
.margin-right.m18{ margin-right: 18px !important; }
.margin-right.m20{ margin-right: 20px !important; }
.margin-right.m22{ margin-right: 22px !important; }
.margin-right.m24{ margin-right: 24px !important; }
.margin-right.m26{ margin-right: 26px !important; }
.margin-right.m28{ margin-right: 28px !important; }
.margin-right.m30{ margin-right: 30px !important; }
.margin-right.m32{ margin-right: 32px !important; }
.margin-right.m34{ margin-right: 34px !important; }
.margin-right.m36{ margin-right: 36px !important; }
.margin-right.m38{ margin-right: 38px !important; }
.margin-right.m40{ margin-right: 40px !important; }
.margin-right.m42{ margin-right: 42px !important; }
.margin-right.m44{ margin-right: 44px !important; }
.margin-right.m46{ margin-right: 46px !important; }
.margin-right.m48{ margin-right: 48px !important; }
.margin-right.m50{ margin-right: 50px !important; }
.margin-right.m52{ margin-right: 52px !important; }
.margin-right.m54{ margin-right: 54px !important; }
.margin-right.m56{ margin-right: 56px !important; }
.margin-right.m58{ margin-right: 58px !important; }
.margin-right.m60{ margin-right: 60px !important; }
.margin-bottom.m0{ margin-bottom: 0px !important; }
.margin-bottom.m1{ margin-bottom: 1px !important; }
.margin-bottom.m2{ margin-bottom: 2px !important; }
.margin-bottom.m3{ margin-bottom: 3px !important; }
.margin-bottom.m4{ margin-bottom: 4px !important; }
.margin-bottom.m5{ margin-bottom: 5px !important; }
.margin-bottom.m6{ margin-bottom: 6px !important; }
.margin-bottom.m7{ margin-bottom: 7px !important; }
.margin-bottom.m8{ margin-bottom: 8px !important; }
.margin-bottom.m9{ margin-bottom: 9px !important; }
.margin-bottom.m10{ margin-bottom: 10px !important; }
.margin-bottom.m12{ margin-bottom: 12px !important; }
.margin-bottom.m14{ margin-bottom: 14px !important; }
.margin-bottom.m16{ margin-bottom: 16px !important; }
.margin-bottom.m18{ margin-bottom: 18px !important; }
.margin-bottom.m20{ margin-bottom: 20px !important; }
.margin-bottom.m22{ margin-bottom: 22px !important; }
.margin-bottom.m24{ margin-bottom: 24px !important; }
.margin-bottom.m26{ margin-bottom: 26px !important; }
.margin-bottom.m28{ margin-bottom: 28px !important; }
.margin-bottom.m30{ margin-bottom: 30px !important; }
.margin-bottom.m32{ margin-bottom: 32px !important; }
.margin-bottom.m34{ margin-bottom: 34px !important; }
.margin-bottom.m36{ margin-bottom: 36px !important; }
.margin-bottom.m38{ margin-bottom: 38px !important; }
.margin-bottom.m40{ margin-bottom: 40px !important; }
.margin-bottom.m42{ margin-bottom: 42px !important; }
.margin-bottom.m44{ margin-bottom: 44px !important; }
.margin-bottom.m46{ margin-bottom: 46px !important; }
.margin-bottom.m48{ margin-bottom: 48px !important; }
.margin-bottom.m50{ margin-bottom: 50px !important; }
.margin-bottom.m52{ margin-bottom: 52px !important; }
.margin-bottom.m54{ margin-bottom: 54px !important; }
.margin-bottom.m56{ margin-bottom: 56px !important; }
.margin-bottom.m58{ margin-bottom: 58px !important; }
.margin-bottom.m60{ margin-bottom: 60px !important; }
.margin-left.m0{ margin-left: 0px !important; }
.margin-left.m1{ margin-left: 1px !important; }
.margin-left.m2{ margin-left: 2px !important; }
.margin-left.m3{ margin-left: 3px !important; }
.margin-left.m4{ margin-left: 4px !important; }
.margin-left.m5{ margin-left: 5px !important; }
.margin-left.m6{ margin-left: 6px !important; }
.margin-left.m7{ margin-left: 7px !important; }
.margin-left.m8{ margin-left: 8px !important; }
.margin-left.m9{ margin-left: 9px !important; }
.margin-left.m10{ margin-left: 10px !important; }
.margin-left.m12{ margin-left: 12px !important; }
.margin-left.m14{ margin-left: 14px !important; }
.margin-left.m16{ margin-left: 16px !important; }
.margin-left.m18{ margin-left: 18px !important; }
.margin-left.m20{ margin-left: 20px !important; }
.margin-left.m22{ margin-left: 22px !important; }
.margin-left.m24{ margin-left: 24px !important; }
.margin-left.m26{ margin-left: 26px !important; }
.margin-left.m28{ margin-left: 28px !important; }
.margin-left.m30{ margin-left: 30px !important; }
.margin-left.m32{ margin-left: 32px !important; }
.margin-left.m34{ margin-left: 34px !important; }
.margin-left.m36{ margin-left: 36px !important; }
.margin-left.m38{ margin-left: 38px !important; }
.margin-left.m40{ margin-left: 40px !important; }
.margin-left.m42{ margin-left: 42px !important; }
.margin-left.m44{ margin-left: 44px !important; }
.margin-left.m46{ margin-left: 46px !important; }
.margin-left.m48{ margin-left: 48px !important; }
.margin-left.m50{ margin-left: 50px !important; }
.margin-left.m52{ margin-left: 52px !important; }
.margin-left.m54{ margin-left: 54px !important; }
.margin-left.m56{ margin-left: 56px !important; }
.margin-left.m58{ margin-left: 58px !important; }
.margin-left.m60{ margin-left: 60px !important; }
/* padding */
.padding.p0{ padding: 0px !important; }
.padding.p1{ padding: 1px !important; }
.padding.p2{ padding: 2px !important; }
.padding.p3{ padding: 3px !important; }
.padding.p4{ padding: 4px !important; }
.padding.p5{ padding: 5px !important; }
.padding.p6{ padding: 6px !important; }
.padding.p7{ padding: 7px !important; }
.padding.p8{ padding: 8px !important; }
.padding.p9{ padding: 9px !important; }
.padding.p10{ padding: 10px !important; }
.padding.p12{ padding: 12px !important; }
.padding.p14{ padding: 14px !important; }
.padding.p16{ padding: 16px !important; }
.padding.p18{ padding: 18px !important; }
.padding.p20{ padding: 20px !important; }
.padding.p22{ padding: 22px !important; }
.padding.p24{ padding: 24px !important; }
.padding.p26{ padding: 26px !important; }
.padding.p28{ padding: 28px !important; }
.padding.p30{ padding: 30px !important; }
.padding.p32{ padding: 32px !important; }
.padding.p34{ padding: 34px !important; }
.padding.p36{ padding: 36px !important; }
.padding.p38{ padding: 38px !important; }
.padding.p40{ padding: 40px !important; }
.padding.p42{ padding: 42px !important; }
.padding.p44{ padding: 44px !important; }
.padding.p46{ padding: 46px !important; }
.padding.p48{ padding: 48px !important; }
.padding.p50{ padding: 50px !important; }
.padding.p52{ padding: 52px !important; }
.padding.p54{ padding: 54px !important; }
.padding.p56{ padding: 56px !important; }
.padding.p58{ padding: 58px !important; }
.padding.p60{ padding: 60px !important; }
.padding.p80{ padding: 80px !important; }
.padding.p100{ padding: 100px !important; }
.padding-top.p0{ padding-top: 0px !important; }
.padding-top.p1{ padding-top: 1px !important; }
.padding-top.p2{ padding-top: 2px !important; }
.padding-top.p3{ padding-top: 3px !important; }
.padding-top.p4{ padding-top: 4px !important; }
.padding-top.p5{ padding-top: 5px !important; }
.padding-top.p6{ padding-top: 6px !important; }
.padding-top.p7{ padding-top: 7px !important; }
.padding-top.p8{ padding-top: 8px !important; }
.padding-top.p9{ padding-top: 9px !important; }
.padding-top.p10{ padding-top: 10px !important; }
.padding-top.p12{ padding-top: 12px !important; }
.padding-top.p14{ padding-top: 14px !important; }
.padding-top.p16{ padding-top: 16px !important; }
.padding-top.p18{ padding-top: 18px !important; }
.padding-top.p20{ padding-top: 20px !important; }
.padding-top.p22{ padding-top: 22px !important; }
.padding-top.p24{ padding-top: 24px !important; }
.padding-top.p26{ padding-top: 26px !important; }
.padding-top.p28{ padding-top: 28px !important; }
.padding-top.p30{ padding-top: 30px !important; }
.padding-top.p32{ padding-top: 32px !important; }
.padding-top.p34{ padding-top: 34px !important; }
.padding-top.p36{ padding-top: 36px !important; }
.padding-top.p38{ padding-top: 38px !important; }
.padding-top.p40{ padding-top: 40px !important; }
.padding-top.p42{ padding-top: 42px !important; }
.padding-top.p44{ padding-top: 44px !important; }
.padding-top.p46{ padding-top: 46px !important; }
.padding-top.p48{ padding-top: 48px !important; }
.padding-top.p50{ padding-top: 50px !important; }
.padding-top.p52{ padding-top: 52px !important; }
.padding-top.p54{ padding-top: 54px !important; }
.padding-top.p56{ padding-top: 56px !important; }
.padding-top.p58{ padding-top: 58px !important; }
.padding-top.p60{ padding-top: 60px !important; }
.padding-right.p0{ padding-right: 0px !important; }
.padding-right.p1{ padding-right: 1px !important; }
.padding-right.p2{ padding-right: 2px !important; }
.padding-right.p3{ padding-right: 3px !important; }
.padding-right.p4{ padding-right: 4px !important; }
.padding-right.p5{ padding-right: 5px !important; }
.padding-right.p6{ padding-right: 6px !important; }
.padding-right.p7{ padding-right: 7px !important; }
.padding-right.p8{ padding-right: 8px !important; }
.padding-right.p9{ padding-right: 9px !important; }
.padding-right.p10{ padding-right: 10px !important; }
.padding-right.p12{ padding-right: 12px !important; }
.padding-right.p14{ padding-right: 14px !important; }
.padding-right.p16{ padding-right: 16px !important; }
.padding-right.p18{ padding-right: 18px !important; }
.padding-right.p20{ padding-right: 20px !important; }
.padding-right.p22{ padding-right: 22px !important; }
.padding-right.p24{ padding-right: 24px !important; }
.padding-right.p26{ padding-right: 26px !important; }
.padding-right.p28{ padding-right: 28px !important; }
.padding-right.p30{ padding-right: 30px !important; }
.padding-right.p32{ padding-right: 32px !important; }
.padding-right.p34{ padding-right: 34px !important; }
.padding-right.p36{ padding-right: 36px !important; }
.padding-right.p38{ padding-right: 38px !important; }
.padding-right.p40{ padding-right: 40px !important; }
.padding-right.p42{ padding-right: 42px !important; }
.padding-right.p44{ padding-right: 44px !important; }
.padding-right.p46{ padding-right: 46px !important; }
.padding-right.p48{ padding-right: 48px !important; }
.padding-right.p50{ padding-right: 50px !important; }
.padding-right.p52{ padding-right: 52px !important; }
.padding-right.p54{ padding-right: 54px !important; }
.padding-right.p56{ padding-right: 56px !important; }
.padding-right.p58{ padding-right: 58px !important; }
.padding-right.p60{ padding-right: 60px !important; }
.padding-bottom.p0{ padding-bottom: 0px !important; }
.padding-bottom.p1{ padding-bottom: 1px !important; }
.padding-bottom.p2{ padding-bottom: 2px !important; }
.padding-bottom.p3{ padding-bottom: 3px !important; }
.padding-bottom.p4{ padding-bottom: 4px !important; }
.padding-bottom.p5{ padding-bottom: 5px !important; }
.padding-bottom.p6{ padding-bottom: 6px !important; }
.padding-bottom.p7{ padding-bottom: 7px !important; }
.padding-bottom.p8{ padding-bottom: 8px !important; }
.padding-bottom.p9{ padding-bottom: 9px !important; }
.padding-bottom.p10{ padding-bottom: 10px !important; }
.padding-bottom.p12{ padding-bottom: 12px !important; }
.padding-bottom.p14{ padding-bottom: 14px !important; }
.padding-bottom.p16{ padding-bottom: 16px !important; }
.padding-bottom.p18{ padding-bottom: 18px !important; }
.padding-bottom.p20{ padding-bottom: 20px !important; }
.padding-bottom.p22{ padding-bottom: 22px !important; }
.padding-bottom.p24{ padding-bottom: 24px !important; }
.padding-bottom.p26{ padding-bottom: 26px !important; }
.padding-bottom.p28{ padding-bottom: 28px !important; }
.padding-bottom.p30{ padding-bottom: 30px !important; }
.padding-bottom.p32{ padding-bottom: 32px !important; }
.padding-bottom.p34{ padding-bottom: 34px !important; }
.padding-bottom.p36{ padding-bottom: 36px !important; }
.padding-bottom.p38{ padding-bottom: 38px !important; }
.padding-bottom.p40{ padding-bottom: 40px !important; }
.padding-bottom.p42{ padding-bottom: 42px !important; }
.padding-bottom.p44{ padding-bottom: 44px !important; }
.padding-bottom.p46{ padding-bottom: 46px !important; }
.padding-bottom.p48{ padding-bottom: 48px !important; }
.padding-bottom.p50{ padding-bottom: 50px !important; }
.padding-bottom.p52{ padding-bottom: 52px !important; }
.padding-bottom.p54{ padding-bottom: 54px !important; }
.padding-bottom.p56{ padding-bottom: 56px !important; }
.padding-bottom.p58{ padding-bottom: 58px !important; }
.padding-bottom.p60{ padding-bottom: 60px !important; }
.padding-left.p0{ padding-left: 0px !important; }
.padding-left.p1{ padding-left: 1px !important; }
.padding-left.p2{ padding-left: 2px !important; }
.padding-left.p3{ padding-left: 3px !important; }
.padding-left.p4{ padding-left: 4px !important; }
.padding-left.p5{ padding-left: 5px !important; }
.padding-left.p6{ padding-left: 6px !important; }
.padding-left.p7{ padding-left: 7px !important; }
.padding-left.p8{ padding-left: 8px !important; }
.padding-left.p9{ padding-left: 9px !important; }
.padding-left.p10{ padding-left: 10px !important; }
.padding-left.p12{ padding-left: 12px !important; }
.padding-left.p14{ padding-left: 14px !important; }
.padding-left.p16{ padding-left: 16px !important; }
.padding-left.p18{ padding-left: 18px !important; }
.padding-left.p20{ padding-left: 20px !important; }
.padding-left.p22{ padding-left: 22px !important; }
.padding-left.p24{ padding-left: 24px !important; }
.padding-left.p26{ padding-left: 26px !important; }
.padding-left.p28{ padding-left: 28px !important; }
.padding-left.p30{ padding-left: 30px !important; }
.padding-left.p32{ padding-left: 32px !important; }
.padding-left.p34{ padding-left: 34px !important; }
.padding-left.p36{ padding-left: 36px !important; }
.padding-left.p38{ padding-left: 38px !important; }
.padding-left.p40{ padding-left: 40px !important; }
.padding-left.p42{ padding-left: 42px !important; }
.padding-left.p44{ padding-left: 44px !important; }
.padding-left.p46{ padding-left: 46px !important; }
.padding-left.p48{ padding-left: 48px !important; }
.padding-left.p50{ padding-left: 50px !important; }
.padding-left.p52{ padding-left: 52px !important; }
.padding-left.p54{ padding-left: 54px !important; }
.padding-left.p56{ padding-left: 56px !important; }
.padding-left.p58{ padding-left: 58px !important; }
.padding-left.p60{ padding-left: 60px !important; }

.padding-lr.p0 { padding-left: 0px !important; padding-right: 0px !important; }
.padding-lr.p1 { padding-left: 1px !important; padding-right: 1px !important; }
.padding-lr.p2 { padding-left: 2px !important; padding-right: 2px !important; }
.padding-lr.p3 { padding-left: 3px !important; padding-right: 3px !important; }
.padding-lr.p4 { padding-left: 4px !important; padding-right: 4px !important; }
.padding-lr.p5 { padding-left: 5px !important; padding-right: 5px !important; }
.padding-lr.p6 { padding-left: 6px !important; padding-right: 6px !important; }
.padding-lr.p7 { padding-left: 7px !important; padding-right: 7px !important; }
.padding-lr.p8 { padding-left: 8px !important; padding-right: 8px !important; }
.padding-lr.p9 { padding-left: 9px !important; padding-right: 9px !important; }
.padding-lr.p10 { padding-left: 10px !important; padding-right: 10px !important; }
.padding-lr.p12 { padding-left: 12px !important; padding-right: 12px !important; }
.padding-lr.p14 { padding-left: 14px !important; padding-right: 14px !important; }
.padding-lr.p16 { padding-left: 16px !important; padding-right: 16px !important; }
.padding-lr.p18 { padding-left: 18px !important; padding-right: 18px !important; }
.padding-lr.p20 { padding-left: 20px !important; padding-right: 20px !important; }
.padding-lr.p22 { padding-left: 22px !important; padding-right: 22px !important; }
.padding-lr.p24 { padding-left: 24px !important; padding-right: 24px !important; }
.padding-lr.p26 { padding-left: 26px !important; padding-right: 26px !important; }
.padding-lr.p28 { padding-left: 28px !important; padding-right: 28px !important; }
.padding-lr.p30 { padding-left: 30px !important; padding-right: 30px !important; }
.padding-lr.p32 { padding-left: 32px !important; padding-right: 32px !important; }
.padding-lr.p34 { padding-left: 34px !important; padding-right: 34px !important; }
.padding-lr.p36 { padding-left: 36px !important; padding-right: 36px !important; }
.padding-lr.p38 { padding-left: 38px !important; padding-right: 38px !important; }
.padding-lr.p40 { padding-left: 40px !important; padding-right: 40px !important; }
.padding-lr.p42 { padding-left: 42px !important; padding-right: 42px !important; }
.padding-lr.p44 { padding-left: 44px !important; padding-right: 44px !important; }
.padding-lr.p46 { padding-left: 46px !important; padding-right: 46px !important; }
.padding-lr.p48 { padding-left: 48px !important; padding-right: 48px !important; }
.padding-lr.p50 { padding-left: 50px !important; padding-right: 50px !important; }
.padding-lr.p52 { padding-left: 52px !important; padding-right: 52px !important; }
.padding-lr.p54 { padding-left: 54px !important; padding-right: 54px !important; }
.padding-lr.p56 { padding-left: 56px !important; padding-right: 56px !important; }
.padding-lr.p58 { padding-left: 58px !important; padding-right: 58px !important; }
.padding-lr.p60 { padding-left: 60px !important; padding-right: 60px !important; }

/* font sizes */
.f8{ font-size: 8px !important; }
.f9{ font-size: 9px !important; }
.f10{ font-size: 10px !important; }
.f11{ font-size: 11px !important; }
.f12{ font-size: 12px !important; }
.f13{ font-size: 13px !important; }
.f14{ font-size: 14px !important; }
.f15{ font-size: 15px !important; }
.f16{ font-size: 16px !important; }
.f17{ font-size: 17px !important; }
.f18{ font-size: 18px !important; }
.f19{ font-size: 19px !important; }
.f20{ font-size: 20px !important; }
.f21{ font-size: 21px !important; }
.f22{ font-size: 22px !important; }
.f23{ font-size: 23px !important; }
.f24{ font-size: 24px !important; }
.f25{ font-size: 25px !important; }
.f26{ font-size: 26px !important; }
.f27{ font-size: 27px !important; }
.f28{ font-size: 28px !important; }
.f29{ font-size: 29px !important; }
.f30{ font-size: 30px !important; }
.f32{ font-size: 32px !important; }
.f34{ font-size: 34px !important; }
.f36{ font-size: 36px !important; }
.f38{ font-size: 38px !important; }
.f40{ font-size: 40px !important; }
.f42{ font-size: 42px !important; }
.f44{ font-size: 44px !important; }
.f46{ font-size: 46px !important; }
.f48{ font-size: 48px !important; }
.f50{ font-size: 50px !important; }
.f52{ font-size: 52px !important; }
.f54{ font-size: 54px !important; }
.f56{ font-size: 56px !important; }
.f58{ font-size: 58px !important; }
.f60{ font-size: 60px !important; }
.f62{ font-size: 62px !important; }
.f64{ font-size: 64px !important; }
.f66{ font-size: 66px !important; }
.f68{ font-size: 68px !important; }
.f70{ font-size: 70px !important; }
/* height */
.h0, .h_0 { height: 0px !important }
.h_1 { height: 1px !important }
.h_2 { height: 2px !important }
.h_3 { height: 3px !important }
.h_4 { height: 4px !important }
.h_5 { height: 5px !important }
.h_6 { height: 6px !important }
.h7 { height: 7px !important }
.h8 { height: 8px !important }
.h9 { height: 9px !important }
.h10 { height: 10px !important }
.h11 { height: 11px !important }
.h12 { height: 12px !important }
.h13 { height: 13px !important }
.h14 { height: 14px !important }
.h15 { height: 15px !important }
.h16 { height: 16px !important }
.h17 { height: 17px !important }
.h18 { height: 18px !important }
.h19 { height: 19px !important }
.h20 { height: 20px !important }
.h21 { height: 21px !important }
.h22 { height: 22px !important }
.h23 { height: 23px !important }
.h24 { height: 24px !important }
.h25 { height: 25px !important }
.h26 { height: 26px !important }
.h27 { height: 27px !important }
.h28 { height: 28px !important }
.h29 { height: 29px !important }
.h30 { height: 30px !important }
.h31 { height: 31px !important }
.h32 { height: 32px !important }
.h33 { height: 33px !important }
.h34 { height: 34px !important }
.h35 { height: 35px !important }
.h36 { height: 36px !important }
.h37 { height: 37px !important }
.h38 { height: 38px !important }
.h39 { height: 39px !important }
.h40 { height: 40px !important }
.h41 { height: 41px !important }
.h42 { height: 42px !important }
.h43 { height: 43px !important }
.h44 { height: 44px !important }
.h45 { height: 45px !important }
.h46 { height: 46px !important }
.h47 { height: 47px !important }
.h48 { height: 48px !important }
.h49 { height: 49px !important }
.h50 { height: 50px !important }
.h51 { height: 51px !important }
.h52 { height: 52px !important }
.h53 { height: 53px !important }
.h54 { height: 54px !important }
.h55 { height: 55px !important }
.h56 { height: 56px !important }
.h57 { height: 57px !important }
.h58 { height: 58px !important }
.h59 { height: 59px !important }
.h60 { height: 60px !important }
.h61 { height: 61px !important }
.h62 { height: 62px !important }
.h63 { height: 63px !important }
.h64 { height: 64px !important }
.h65 { height: 65px !important }
.h66 { height: 66px !important }
.h67 { height: 67px !important }
.h68 { height: 68px !important }
.h69 { height: 69px !important }
.h70 { height: 70px !important }
.h71 { height: 71px !important }
.h72 { height: 72px !important }
.h73 { height: 73px !important }
.h74 { height: 74px !important }
.h75 { height: 75px !important }
.h76 { height: 76px !important }
.h77 { height: 77px !important }
.h78 { height: 78px !important }
.h79 { height: 79px !important }
.h80 { height: 80px !important }
.h81 { height: 81px !important }
.h82 { height: 82px !important }
.h83 { height: 83px !important }
.h84 { height: 84px !important }
.h85 { height: 85px !important }
.h86 { height: 86px !important }
.h87 { height: 87px !important }
.h88 { height: 88px !important }
.h89 { height: 89px !important }
.h90 { height: 90px !important }
.h91 { height: 91px !important }
.h92 { height: 92px !important }
.h93 { height: 93px !important }
.h94 { height: 94px !important }
.h95 { height: 95px !important }
.h96 { height: 96px !important }
.h97 { height: 97px !important }
.h98 { height: 98px !important }
.h99 { height: 99px !important }
.h100 { height: 100px !important }
.h0p { height: 0% !important }
.h1p { height: 1% !important }
.h2p { height: 2% !important }
.h3p { height: 3% !important }
.h4p { height: 4% !important }
.h5p { height: 5% !important }
.h6p { height: 6% !important }
.h7p { height: 7% !important }
.h8p { height: 8% !important }
.h9p { height: 9% !important }
.h10p { height: 10% !important }
.h11p { height: 11% !important }
.h12p { height: 12% !important }
.h13p { height: 13% !important }
.h14p { height: 14% !important }
.h15p { height: 15% !important }
.h16p { height: 16% !important }
.h17p { height: 17% !important }
.h18p { height: 18% !important }
.h19p { height: 19% !important }
.h20p { height: 20% !important }
.h21p { height: 21% !important }
.h22p { height: 22% !important }
.h23p { height: 23% !important }
.h24p { height: 24% !important }
.h25p { height: 25% !important }
.h26p { height: 26% !important }
.h27p { height: 27% !important }
.h28p { height: 28% !important }
.h29p { height: 29% !important }
.h30p { height: 30% !important }
.h31p { height: 31% !important }
.h32p { height: 32% !important }
.h33p { height: 33% !important }
.h34p { height: 34% !important }
.h35p { height: 35% !important }
.h36p { height: 36% !important }
.h37p { height: 37% !important }
.h38p { height: 38% !important }
.h39p { height: 39% !important }
.h40p { height: 40% !important }
.h41p { height: 41% !important }
.h42p { height: 42% !important }
.h43p { height: 43% !important }
.h44p { height: 44% !important }
.h45p { height: 45% !important }
.h46p { height: 46% !important }
.h47p { height: 47% !important }
.h48p { height: 48% !important }
.h49p { height: 49% !important }
.h50p { height: 50% !important }
.h51p { height: 51% !important }
.h52p { height: 52% !important }
.h53p { height: 53% !important }
.h54p { height: 54% !important }
.h55p { height: 55% !important }
.h56p { height: 56% !important }
.h57p { height: 57% !important }
.h58p { height: 58% !important }
.h59p { height: 59% !important }
.h60p { height: 60% !important }
.h61p { height: 61% !important }
.h62p { height: 62% !important }
.h63p { height: 63% !important }
.h64p { height: 64% !important }
.h65p { height: 65% !important }
.h66p { height: 66% !important }
.h67p { height: 67% !important }
.h68p { height: 68% !important }
.h69p { height: 69% !important }
.h70p { height: 70% !important }
.h71p { height: 71% !important }
.h72p { height: 72% !important }
.h73p { height: 73% !important }
.h74p { height: 74% !important }
.h75p { height: 75% !important }
.h76p { height: 76% !important }
.h77p { height: 77% !important }
.h78p { height: 78% !important }
.h79p { height: 79% !important }
.h80p { height: 80% !important }
.h81p { height: 81% !important }
.h82p { height: 82% !important }
.h83p { height: 83% !important }
.h84p { height: 84% !important }
.h85p { height: 85% !important }
.h86p { height: 86% !important }
.h87p { height: 87% !important }
.h88p { height: 88% !important }
.h89p { height: 89% !important }
.h90p { height: 90% !important }
.h91p { height: 91% !important }
.h92p { height: 92% !important }
.h93p { height: 93% !important }
.h94p { height: 94% !important }
.h95p { height: 95% !important }
.h96p { height: 96% !important }
.h97p { height: 97% !important }
.h98p { height: 98% !important }
.h99p { height: 99% !important }
.h100p { height: 100% !important }
/* width */
.w0 { width: 0px !important }
.w1 { width: 1px !important }
.w2 { width: 2px !important }
.w3 { width: 3px !important }
.w4 { width: 4px !important }
.w5 { width: 5px !important }
.w6 { width: 6px !important }
.w7 { width: 7px !important }
.w8 { width: 8px !important }
.w9 { width: 9px !important }
.w10 { width: 10px !important }
.w12 { width: 12px !important }
.w14 { width: 14px !important }
.w15 { width: 15px !important }
.w16 { width: 16px !important }
.w18 { width: 18px !important }
.w20 { width: 20px !important }
.w22 { width: 22px !important }
.w24 { width: 24px !important }
.w25 { width: 25px !important }
.w26 { width: 26px !important }
.w28 { width: 28px !important }
.w30 { width: 30px !important }
.w32 { width: 32px !important }
.w34 { width: 34px !important }
.w35 { width: 35px !important }
.w36 { width: 36px !important }
.w38 { width: 38px !important }
.w40 { width: 40px !important }
.w42 { width: 42px !important }
.w44 { width: 44px !important }
.w45 { width: 45px !important }
.w46 { width: 46px !important }
.w48 { width: 48px !important }
.w50 { width: 50px !important }
.w52 { width: 52px !important }
.w54 { width: 54px !important }
.w55 { width: 55px !important }
.w56 { width: 56px !important }
.w58 { width: 58px !important }
.w60 { width: 60px !important }
.w62 { width: 62px !important }
.w64 { width: 64px !important }
.w65 { width: 65px !important }
.w66 { width: 66px !important }
.w68 { width: 68px !important }
.w70 { width: 70px !important }
.w72 { width: 72px !important }
.w74 { width: 74px !important }
.w75 { width: 75px !important }
.w76 { width: 76px !important }
.w78 { width: 78px !important }
.w80 { width: 80px !important }
.w82 { width: 82px !important }
.w84 { width: 84px !important }
.w85 { width: 85px !important }
.w86 { width: 86px !important }
.w88 { width: 88px !important }
.w90 { width: 90px !important }
.w92 { width: 92px !important }
.w94 { width: 94px !important }
.w95 { width: 95px !important }
.w96 { width: 96px !important }
.w98 { width: 98px !important }
.w100 { width: 100px !important }
.w102 { width: 102px !important }
.w104 { width: 104px !important }
.w105 { width: 105px !important }
.w106 { width: 106px !important }
.w108 { width: 108px !important }
.w110 { width: 110px !important }
.w112 { width: 112px !important }
.w114 { width: 114px !important }
.w115 { width: 115px !important }
.w116 { width: 116px !important }
.w118 { width: 118px !important }
.w120 { width: 120px !important }
.w122 { width: 122px !important }
.w124 { width: 124px !important }
.w125 { width: 125px !important }
.w126 { width: 126px !important }
.w128 { width: 128px !important }
.w130 { width: 130px !important }
.w132 { width: 132px !important }
.w134 { width: 134px !important }
.w135 { width: 135px !important }
.w136 { width: 136px !important }
.w138 { width: 138px !important }
.w140 { width: 140px !important }
.w142 { width: 142px !important }
.w144 { width: 144px !important }
.w145 { width: 145px !important }
.w146 { width: 146px !important }
.w148 { width: 148px !important }
.w150 { width: 150px !important }
.w152 { width: 152px !important }
.w154 { width: 154px !important }
.w155 { width: 155px !important }
.w156 { width: 156px !important }
.w158 { width: 158px !important }
.w160 { width: 160px !important }
.w162 { width: 162px !important }
.w164 { width: 164px !important }
.w165 { width: 165px !important }
.w166 { width: 166px !important }
.w168 { width: 168px !important }
.w170 { width: 170px !important }
.w172 { width: 172px !important }
.w174 { width: 174px !important }
.w175 { width: 175px !important }
.w176 { width: 176px !important }
.w178 { width: 178px !important }
.w180 { width: 180px !important }
.w182 { width: 182px !important }
.w184 { width: 184px !important }
.w185 { width: 185px !important }
.w186 { width: 186px !important }
.w188 { width: 188px !important }
.w190 { width: 190px !important }
.w192 { width: 192px !important }
.w194 { width: 194px !important }
.w195 { width: 195px !important }
.w196 { width: 196px !important }
.w198 { width: 198px !important }
.w200 { width: 200px !important }
.w250 { width: 250px !important }

.w0p { width: 0% !important }
.w1p { width: 1% !important }
.w2p { width: 2% !important }
.w3p { width: 3% !important }
.w4p { width: 4% !important }
.w5p { width: 5% !important }
.w6p { width: 6% !important }
.w7p { width: 7% !important }
.w8p { width: 8% !important }
.w9p { width: 9% !important }
.w10p { width: 10% !important }
.w12p { width: 12% !important }
.w14p { width: 14% !important }
.w15p { width: 15% !important }
.w16p { width: 16% !important }
.w18p { width: 18% !important }
.w20p { width: 20% !important }
.w22p { width: 22% !important }
.w24p { width: 24% !important }
.w25p { width: 25% !important }
.w26p { width: 26% !important }
.w28p { width: 28% !important }
.w30p { width: 30% !important }
.w32p { width: 32% !important }
.w34p { width: 34% !important }
.w35p { width: 35% !important }
.w36p { width: 36% !important }
.w38p { width: 38% !important }
.w40p { width: 40% !important }
.w42p { width: 42% !important }
.w44p { width: 44% !important }
.w45p { width: 45% !important }
.w46p { width: 46% !important }
.w48p { width: 48% !important }
.w50p { width: 50% !important }
.w52p { width: 52% !important }
.w54p { width: 54% !important }
.w55p { width: 55% !important }
.w56p { width: 56% !important }
.w58p { width: 58% !important }
.w60p { width: 60% !important }
.w62p { width: 62% !important }
.w64p { width: 64% !important }
.w65p { width: 65% !important }
.w66p { width: 66% !important }
.w68p { width: 68% !important }
.w70p { width: 70% !important }
.w72p { width: 72% !important }
.w74p { width: 74% !important }
.w75p { width: 75% !important }
.w76p { width: 76% !important }
.w78p { width: 78% !important }
.w80p { width: 80% !important }
.w82p { width: 82% !important }
.w84p { width: 84% !important }
.w85p { width: 85% !important }
.w86p { width: 86% !important }
.w88p { width: 88% !important }
.w90p { width: 90% !important }
.w92p { width: 92% !important }
.w94p { width: 94% !important }
.w95p { width: 95% !important }
.w96p { width: 96% !important }
.w98p { width: 98% !important }
.w100p { width: 100% !important }
/* text colors */
.c000{ color: #000 !important }
.c333{ color: #333 !important }
.c666{ color: #666 !important }
.cfff { color: #fff !important }
.c999 { color: #999 !important }
.cccc { color: #ccc !important }
.cf00 { color: #f00 !important }
.cf90 { color: #f90 !important }
.c0f0 { color: #0f0 !important }
.c0a0 { color: #0a0 !important }
.c090 { color: #090 !important }
.c060 { color: #060 !important }
.c06f { color: #06f !important }
.c09f { color: #09f !important }
.c0cf { color: #0cf !important }
.c00f { color: #00f !important }
.c00c { color: #00c !important }
.c00a { color: #00a !important }
.c009 { color: #009 !important }
.c006 { color: #006 !important }
.ce30517, .ccim { color: #e30517 !important }
.c8F53D0, .cdc { color: #8F53D0 !important }
.c448085, .ccad { color: #448085 !important }
.c0075F6, .cdup { color: #0075F6  !important }
.c00BECA, .cvl, .cvel { color: #00BECA !important }
.c118400, .cda { color: #118400 !important }
.c56423E, .ccsa { color: #56423E !important }
.ce30aaa, .cciww, ccivi { color: #e30aaa !important }

/* bg color */
.bg000 { background-color: #000 !important }
.bgfff { background-color: #fff !important }
.bge0e0e0 { background-color: #e0e0e0 !important }
.bgf0f0f0 { background-color: #f0f0f0 !important }
.bgf7f7f7 { background-color: #f7f7f7 !important }
.bgfafafa { background-color: #fafafa !important }
.bgfcfcfc { background-color: #fcfcfc !important }
.bg999 { background-color: #999 !important }
.bgccc { background-color: #ccc !important }
.bgf00 { background-color: #f00 !important }
.bg900 { background-color: #900 !important }
.bge30517, .bgcim { background-color: #e30517 !important }
.bg8F53D0, .bgdc { background-color: #8F53D0 !important }
.bg448085, .bgcad { background-color: #448085 !important }
.bg0075F6, .bgdup { background-color: #0075F6 !important }
.bg00BECA, .bgvl, .bgvel { background-color: #00BECA !important }
.bg118400, .bgda { background-color: #118400 !important }
.bg56423E, .bgcsa { background-color: #56423E !important }
.bge30aaa, .bgciww, bgcivi { background-color: #e30aaa !important }
.bg090 { background-color: #090 !important }
.bg0a0 { background-color: #0a0 !important }

/*----------------------------------------------------------------------------*/

.no-padding { padding: 0 !important; }
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }

.no-margin { margin: 0 !important; }
.no-margin-left { margin-left: 0 !important; }
.no-margin-right { margin-right: 0 !important; }
.no-margin-top { margin-top: 0 !important; }
.no-margin-bottom { margin-bottom: 0 !important; }
.end_of_defaults_css{padding:0}