.terminals-list-page table.terminals-list {
    border-collapse: unset;
}
.terminals-list-page table.terminals-list thead {
    background: linear-gradient(#fff, #f7f7f7);
}
.terminals-list-page table.terminals-list th.md-column {
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #000 !important;
}
.terminals-list-page table.terminals-list tr.offline {
    background: #FFE5E8
}
.terminals-list-page table.terminals-list tr.online {
    background: rgba(0, 250, 0, 0.05)
}
.terminals-list-page table.terminals-list tr.not-active {
    background: rgba(0, 0, 0, 0.03)
}
.terminals-list-page table.terminals-list td {
    padding: 4px
}
.terminals-list-page table.terminals-list td.terminal {
    padding-top: 10px !important;
    padding-left: 10px !important;
    border-left: 15px solid;
}
.terminals-list-page table.terminals-list td.terminal.online {
    border-left-color: #080;
}
.terminals-list-page table.terminals-list td.terminal.offline {
    border-left-color: #e00;
}
/*.terminals-list-page .pagination-container .filters-container md-input-container {
    margin-top: 25px !important;
    margin-bottom: 5px !important;
}*/
.main-list-container .btn-show-search {
    top: 7px;
    right: 7px;
}
md-menu.terminal-actions md-menu-item > .md-button {
    border-bottom: 1px solid #ccc;
}