.tablets-list-page table.tablets-list {
    border-collapse: unset;
}
.tablets-list-page table.tablets-list thead {
    background: linear-gradient(#fff, #f7f7f7);
}
.tablets-list-page table.tablets-list th.md-column {
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #000 !important;
}
.tablets-list-page table.tablets-list tr.offline {
    background: #FFE5E8
}
.tablets-list-page table.tablets-list tr.online {
    background: rgba(0, 250, 0, 0.05)
}
.tablets-list-page table.tablets-list tr.not-active {
    background: rgba(0, 0, 0, 0.03)
}
.tablets-list-page table.tablets-list td {
    padding: 4px
}
.tablets-list-page table.tablets-list td.tablet {
    padding-top: 10px !important;
    padding-left: 10px !important;
    border-left: 15px solid;
}
.tablets-list-page table.tablets-list td.tablet.online {
    border-left-color: #080;
}
.tablets-list-page table.tablets-list td.tablet.offline {
    border-left-color: #e00;
}
/*.tablets-list-page .pagination-container .filters-container md-input-container {
    margin-top: 25px !important;
    margin-bottom: 5px !important;
}*/
.main-list-container .btn-show-search {
    top: 7px;
    right: 7px;
}