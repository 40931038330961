/*
    imports
*/
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Round'); /*|Rubik|Ubuntu|Oxygen*/
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap');

/*
    angular
*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}
/*
    common
*/
* {
    color: #000
}
body {
    font-family: heebo, sans-serif;
    font-size: 13px;
    color: red;
    background: repeating-linear-gradient(45deg, #fff, #fff 10px, #fcfcfc 10px, #fcfcfc 20px);
}/*
span {
    font-size: 14px;
}*/
hr {
    margin-top: 8px;
    margin-bottom: 16px
}
/* scrollbar */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.3);
    border-radius: 0;
    background-color: #fff;
}
::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,.3);
    background-color: #e0e0e0;
}

/*
    my classes
*/
.inline {
    display: inline !important;
}
.right {
    float: right !important;
}
.right-absolute {
    position: absolute !important;
    right: 0;
}
.left {
    float: left !important;
}
/* text transform */
.upper, .uppercase {
    text-transform: uppercase
}
.lower, .lowercase {
    text-transform: lowercase
}
.capital, .capitalize {
    text-transform: capitalize
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.text-top {
    vertical-align: top !important;
}
.text-bottom {
    vertical-align: bottom !important;
}
.bold { font-weight: 700 !important; }
.normal { font-weight: 400 !important; }

.odd {
    background-color: #f9f9f9 !important;
}
.green-text {
    color: #090 !important;
}
.red-text {
    color: #F00 !important;
}
.center-text {
    text-align: center !important
}

/*
    icons
*/
.icon {

}
.icon.i18, md-icon.i18 {
    font-size: 22px;
    height: 18px;
    width: 18px;
    min-width: 18px;
    min-height: 18px;
}
.icon.i24, md-icon.i24 {
    font-size: 24px;
    height: 24px;
    width: 24px;
}
.icon.i32, md-icon.i32 {
    font-size: 32px;
    height: 32px;
    width: 32px;
}
.icon.i48, md-icon.i48 {
    font-size: 48px;
    height: 48px;
    width: 48px;
}
.icon.i64, md-icon.i64 {
    font-size: 64px;
    height: 64px;
    width: 64px;
}


/* border radiuses */
.no-border-radius, .border-radius.br0 {
    border-radius: 0 !important
}
.border-radius.br5 {
    border-radius: 5px
}
.border-radius.br10 {
    border-radius: 10px
}
.border-radius.br15 {
    border-radius: 15px
}
.border-radius.br20 {
    border-radius: 20px
}
.no-border {
    border: none !important
}
.no-border-top {
    border-top: none !important
}
.no-border-right {
    border-right: none !important
}
.no-border-bottom {
    border-bottom: none !important
}
.no-border-left {
    border-left: none !important
}
/* general */
tr.disabled td, tr.disabled td b {
    color: #999;
    font-weight: 400;
    opacity: 0.8
}
/* 
    md-content
*/
md-content {
    background: transparent;
}
md-content#content {
    background: repeating-linear-gradient(45deg, #fff, #fff 10px, #fafafa 10px, #fafafa 20px);
    padding-bottom: 63px;
}
md-content#content md-table-container {
    background: #fff;
}
/*
    md-tab
*/
md-tabs-canvas {
    border-bottom: 1px solid #ccc;
}
.notif {
    position: absolute;
    right: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
}
.md-tab {
    color: red;
    text-transform: none;
}
.md-tab md-icon {
}
md-tab-body {
    padding: 10px
}
md-tabs .md-tab {
    color: #000;
    font-weight: 700;
}
/*
    md-table
*/
table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2),
table.md-table td.md-cell:last-child,
table.md-table th.md-column:last-child {
    padding: 0
}
/*
    md-button
*/
.md-button {
    border-radius: 3px;
    text-transform: none;
}
.md-button.btn-submit {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
}
.md-button.btn-right-absolute {
    position: absolute;
    margin-right: 0px !important;
    right: 10px;
}
.md-button.btn-right {
    position: relative;
    float: right;
}
.md-button.btn-right::before {
    height: 1px;
    width: 100%;
    border-top: 1px solid red;
}
.md-button.btn {
    text-transform: none;
}
.md-button.btn.red {
    background: linear-gradient(#b00, #900);
    /*background-color: rgba(120,0,0,0.9);*/
    color: rgba(255,255,255,0.9);
}
.md-button.btn.red:hover {
    background: linear-gradient(#c00, #a00);
    /*background-color: #f00;*/
    color: #fff;
}
.md-button.btn.green {
    background: linear-gradient(#0b0, #060);
    color: rgba(255,255,255,0.9);
}
.md-button.btn.green:hover {
    background: linear-gradient(#090, #040);
    color: #fff;
}
.md-button.btn.green:active {
    background: linear-gradient(#080, #030);
    color: #fff;
}
/*
    md-toast
*/
md-toast {
}

md-toast div.md-toast-content {
    color: white !important;
    border-radius: 3px;
}
md-toast.md-error-toast-theme div.md-toast-content{
    background-color: rgba(220,0,0,0.9) !important;
}

md-toast.md-success-toast-theme div.md-toast-content{
    background-color: rgba(0,120,0,0.9) !important;
}

.md-sidemenu .md-button {
    border-top: 1px solid #f0f0f0;
    /*padding: 0 10px;*/
    padding: 0 5px;
    height: 40px !important;
    min-height: 40px !important;
    
}

.md-subheader .md-subheader-inner {
    padding: 0 8px;
}

md-checkbox.red .md-icon {
    border: 2px solid transparent;
    background-color: rgba(227,6,20,0.9);
}

md-autocomplete input[type = 'text'] {
    color: initial !important
}

/*
    user's role & permission
*/
.password-generator {
    padding-left: 10px;
    padding-right: 10px;
    text-transform: none;
}
.role, .permission {
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 3px;
    color: #fff;
    margin: 3px;
    display: inline-block;
}
.role.admin {
    background-color: red;
}
.role.user {
    background-color: green;
}
.permission {
    background-color: #ccc;
}
.permission.admin-all {
    background-color: #000;
}
.permission.all {
    background-color: #666;
}
.permission.post {
    background-color: #0d0;
}
.permission.put {
    background-color: #f60;
}
.permission.delete {
    background-color: #f00;
}
.permission.get {
    background-color: #00f;
}
/* 
    pagination
*/
.pagination>li>a,
.pagination>li>span {
    color: #666;
    border-color: #ccc;
}
.pagination>li>a:hover,
.pagination>li>span:hover {
    border-color: #ccc;
}
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #E30614;
    border-color: #E30614;
}
.pagination-container {
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
    width: 100%;
    background-color: #fff;
    padding: 10px;
    display: inline-block;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
}
.pagination-container .pagination-items-nb {
    float: left;
    height: 40px;
}
.pagination-container .pagination-items-nb md-select {
    width: 120px;
    float: left;
}
.pagination-container .pagination-items-nb .text {
    display: inline-block;
    padding-top: 10px;
    margin-left: 10px;
    color: #666;
}
.pagination-container .paginator {
    float:right;
}
.pagination-container > md-fab-toolbar {
    width: 100%;
    margin-left: 100px;
    height: 100%;
}
.pagination-container > md-toolbar {
    background-color: #fff !important;
}
.pagination-container .md-errors-spacer {
    display: none
}
.pagination-container .filters-container md-input-container {
    min-width: 120px;
    margin-left: 15px;
}
.pagination-container .filters-container md-input-container .md-input-has-value {
    min-width: 120px;
}
.pagination-container .btn-clear-container {
    float: right;
    height: 24px;
    width: 24px;
    min-width: 24px !important;
}
.pagination-container .btn-clear-container .md-icon-button {
    position: absolute;
    height: 24px;
    width: 24px;
    right: 0;
    top: 0;
    border-radius: 0
}
.pagination-container md-datepicker .md-datepicker-input-container {
    width: 150px
}

/*
    packs 
*/
.btn-check {
    width: 24px !important;
    height: 24px !important;
    min-height: 24px;
    min-width: 24px;
    padding: 0;
    border-radius: 15px;
}
.btn-check md-icon {
    width: 24px;
    font-size: 24px;
    color: #fff;
    vertical-align: top !important;
}
.btn-check:hover {
    background:  #06f
}
.btn-check.active {
    background: #0a0;
    color: #fff;
}
.btn-check.not-active {
    background: #a00;
    color: #fff;
}
.btn-check.disabled {
    background: #999;
    color: #fff;
}

tr.not-active {
    background: #f0f0f0
}
tr.not-active span, tr.not-active h2, tr.not-active p, tr.not-active b {
    color: #888;
}
/*
    md-dialog
*/
md-dialog {
    border-radius: 8px;
}
md-dialog md-toolbar .md-toolbar-tools {
    height: 48px;
    padding-left: 15px !important;
    padding-bottom: 0px !important;
    background: linear-gradient(#fff, #f0f0f0) !important;

}
md-dialog md-toolbar .md-toolbar-tools h2 {
    font-size: 16px;
    font-weight: 700;
}
md-dialog .md-actions, md-dialog md-dialog-actions {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
md-dialog .buttons-container {
    width: 100%;
    text-align: right;
    padding-left: 10px;
    padding-right: 10px;
    background: #fafafa;
    border-top: 1px solid #ccc;
}

md-dialog[md-theme=confirm] {
    min-width: 33%;
}

md-dialog[md-theme=confirm] md-dialog-content {
    padding: 16px;
    overflow: hidden;
    /*border: 3px solid red;*/
}

md-dialog[md-theme=confirm] md-dialog-content h2.md-title {
    font-size: 16px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 10px;
    padding-left: 15px;
    font-weight: 700;
}

md-dialog[md-theme=confirm] md-dialog-actions {
    border-top: 1px solid #ccc;
    padding-right: 10px !important;
    background: #f0f0f0;
    margin-top: 20px;
}
md-dialog[role=dialog] md-dialog-actions .md-button {
    /*width: 110px;*/
}
md-dialog[md-theme=confirm] md-dialog-actions .md-button.md-cancel-button  {
    background-color: rgba(0,153,0,.75);
    color: #fff;
}
md-dialog[md-theme=confirm] md-dialog-actions .md-button.md-cancel-button:hover  {
    background-color: rgba(0,153,0,1);
}
md-dialog[md-theme=confirm] md-dialog-actions .md-button.md-confirm-button {
    background-color: rgba(227,6,20,.75);
    color: #fff;
}
md-dialog[md-theme=confirm] md-dialog-actions .md-button.md-confirm-button:hover  {
    background-color: rgba(227,6,20,1);
}

/*
    badge
*/
.badge {
    color: #fff;
    padding: 8px !important;
    border-radius: 4px;
    /*font-size: 14px;*/
    font-weight: 400;
}
.badge.client-particulier {
    background-color: #f60;
}
.badge.client-professionnel {
    background-color: #d00;
}
.badge.professionnel {
    background-color: #0d0;
}
.badge.partenaire {
    background-color: #09d;
}
.badge.new {
    color: #000;
    background-color: #5f5;
}
.badge.not-approved {
    background-color: #f00;
}
.badge.waiting-to-upload-documents,
.badge.waiting-to-sign-documents {
    background-color: #f90;
}
.badge.waiting-for-documents-verification {
    color: #000;
    background-color: #ff0;
    border: 1px solid #000;
}
.badge.waiting-for-final-acceptance {
    color: #000;
    background-color: lightgreen;
    border: 1px solid #000;
}
.badge.approved {
    background-color: #0a0;
}
.badge.banned {
    background-color: #f00
}
/* document statuses */
.badge.to_upload {
    background-color: #5588EE;
}
.badge.uploaded {
    background-color: #4422EE;
}
.badge.to_sign {
    background-color: #C244FF;
}
.badge.signed {
    background-color: #7400AC;
}
.badge.accepted, .badge.validated {
    background-color: #0a0;
}
.badge.rejected {
    background-color: #e00;
}
.badge.rejected_by_client {
    background-color: #f00;
}
.badge.yes, .badge.no {
    color: white;
    padding: 2px;
    border-radius: 5px;
    min-height: 24px;
    max-height: 32px;
}
.badge.yes {
    background-color: #090;
}
.badge.no {
    background-color: #E00;
}
/*
    client page
*/
.client-infos md-icon {
    /** color: #E30614;**/
    margin-left: 10px;
    margin-right: 12px;
}
.client .md-title {
    color: #434345;
    font-size: 16px !important;
}
.client .list thead {
    border-bottom: 1px dashed #ccc;
}
.client .list th {
    font-size: 16px
}
.client .list th,
.client .list td {
    text-align: center;
    padding: 10px
}

.client .list .sub-list thead {
    border-bottom: 1px dashed #ccc;
}
.client .list .sub-list th {
    font-size: 13px;
    font-weight: 400;
}
.client .list .sub-list th,
.client .list .sub-list td {
    text-align: center;
    padding: 5px
}

/***************************/
.origin-icon-app-mobile md-icon {
    color: red
}
.origin-icon-borne md-icon{
    color: #f90
}
.origin-icon-site-web md-icon{
    color: #090
}
.origin-icon-bo md-icon{
    color: #000
}
.origin-icon-tablette md-icon{
    color: #03f
}

md-toolbar.page-header {
    background-color: #222 !important;
    margin: 0;
    padding: 0;
    color: #fff;
}

md-toolbar {
    height: auto;
    min-height: 48px;
    max-height: 64px;
    background: linear-gradient(#fff, #f0f0f0);
    border-bottom: 1px solid #ccc;
    /*box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);*/
}

md-toolbar:not(.md-primary) .md-toolbar-tools {
    height: 48px;
    padding: 5px 10px;
    background: transparent;
}

fieldset.standard {
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;
    border-radius: 6px;
    padding: 15px;
    padding-bottom: 0;
}
fieldset.standard legend {
    padding: 5px;
    font-size: 13px;
    font-weight: 700;
    border: 0;
    margin: 0
}
.message {
    border-radius: 5px;
    padding: 10px;
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    font-family: 'Courier New', Arial
}

/**** *****/
.item-field input[type='text']{
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #f00;
    font-size: 12px;
}
.item-buttons .md-button.md-icon-button {
    height: 18px;
    width: 18px;
    min-width: 18px;
    max-width: 18px;
    min-height: 18px;
    max-height: 18px;
    margin: 0;
    padding: 0;
    border: none;
    overflow: visible
}
.item-buttons .btn-edit md-icon{
    font-size: 16px;
    color: red;
    border-radius: 50%;
    text-align: center;
    padding: 3px;
    width: 22px;
    height: 22px;
    margin-top: -4px;
    margin-left: 2px;
}
.clientForm{
    font-size: 16px;
}
.boldh4{
    font-weight: bold;
}
.margin-top-11
{
    margin-top: -11px;
}
.f16 {
    font-size: 16px;
}
.copy-icon {
    color: #bbb;
    font-size: 18px;
    cursor: pointer;
}
.copy-icon:active {
    color: red;
}
md-card {
    margin: 15px
}

.md-sidemenu .md-subheader {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
    background: linear-gradient(#f0f0f0, #fafafa);
}
.supports{
    padding: 10px;
    background: #801f00;
    color: white;
    border-radius: 4px;
    margin: 10px;  
}
.supports.terminal{
    background: #801f00;
}
.supports.tablet{
    background: #4d0080;
}
.supports.webapp{
    background: #047604;
}
.supports:hover,.supports:focus{
    text-decoration: none;
    color: #ffffff;
}