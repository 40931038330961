.autocomplete {
    position: relative;
}

.autocomplete ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 2;
}

.autocomplete li {
    padding: 8px;
    cursor: pointer;
}

.autocomplete li:hover {
    background-color: #eee;
}
.autocomplete md-input-container{
    min-width: fit-content;
    max-width: 400px;
}
